import React from 'react';

function UnderDev() {
  return (
    <div className="text-2xl text-pink-300 flex justify-center items-center h-screen">
      Page Under Construction.
    </div>
  );
}

export default UnderDev;
